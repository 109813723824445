import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import Config from '../../Config';
import { toast } from 'react-toastify';

const QuestionForm = ({
  question,
  onSubmit = () => {},
  type = 'add',
  currentUser,
}) => {
  const [newQuestion, setNewQuestion] = useState(question ?? {});
  const [topics, setTopoics] = useState([]);
  const [category] = useState([
    {
      name: 'Snel',
      value: 'quick',
    },
    {
      name: 'Uitgebreid',
      value: 'extensive',
    },
  ]);
  const [types] = useState([
    {
      name: 'Bereik',
      value: 'range',
    },
    {
      name: 'Ja/Nee',
      value: 'yes/no',
    },
  ]);

  const fetchTopics = async () => {
    try {
      const {
        data: { data },
      } = await Axios.get(
        `${Config.apiUrl}/api/v1/survey-topics?organization=${currentUser?.organization?._id}`,
      );

      setTopoics(data);
    } catch (error) {
      console.log('errr', error);
      toast('Failed to fetch data. Please try again later.', {
        type: 'error',
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validate(newQuestion)) return;
    let question;

    if (newQuestion?.type === 'yes/no') {
      question = {
        ...newQuestion,
        property: {
          ...newQuestion?.property,
          startText: '',
          endText: '',
        },
      };
    } else if (newQuestion?.type === 'range') {
      question = {
        ...newQuestion,
        property: {
          ...newQuestion?.property,
          advices: {
            yes: '',
            no: '',
          },
        },
      };
    }

    onSubmit(question, setNewQuestion);
  };

  const validate = (question) => {
    const { topic } = question;
    let message = '';

    if (!topic) {
      message = 'Please select a topic';
    }

    if (message) {
      toast(message, {
        type: 'error',
      });
    }

    return message.length === 0;
  };

  useEffect(() => {
    fetchTopics();
    // eslint-disable-next-line
  }, []);

  return (
    <form
      className={`grid grid-cols-2 gap-4 ${
        type !== 'update' && 'w-full md:w-[600px]'
      }`}
      onSubmit={handleSubmit}
    >
      <input
        className="w-full text-black rounded input bg-transparent"
        placeholder="Title"
        required
        value={newQuestion?.title || ''}
        onChange={(event) =>
          setNewQuestion({ ...newQuestion, title: event.target.value })
        }
      />
      <select
        className="select rounded"
        value={newQuestion?.category}
        onChange={(event) =>
          setNewQuestion({ ...newQuestion, category: event.target.value })
        }
        required
      >
        <option value="">Selecteer vragenlijst</option>
        {category.map((item, index) => (
          <option key={index} value={item.value}>
            {item.name}
          </option>
        ))}
      </select>
      <select
        className="select rounded"
        value={newQuestion?.topic}
        onChange={(event) =>
          setNewQuestion({ ...newQuestion, topic: event.target.value })
        }
        required
      >
        <option value="">Selecteer onderwerp</option>
        {topics.map((item, index) => (
          <option key={index} value={item._id}>
            {item.title}
          </option>
        ))}
      </select>
      <select
        className="select rounded"
        value={newQuestion?.type}
        onChange={(event) =>
          setNewQuestion({ ...newQuestion, type: event.target.value })
        }
        required
      >
        <option value="">Selecteer type</option>
        {types.map((item, index) => (
          <option key={index} value={item.value}>
            {item.name}
          </option>
        ))}
      </select>
      {!newQuestion?.type || newQuestion?.type === 'range' ? (
        <>
          <input
            className="w-full text-black rounded input bg-transparent"
            placeholder="Start tekst"
            required
            value={newQuestion.property?.startText || ''}
            onChange={(event) =>
              setNewQuestion({
                ...newQuestion,
                property: {
                  ...newQuestion.property,
                  startText: event.target.value,
                },
              })
            }
          />
          <input
            className="w-full text-black rounded input bg-transparent"
            placeholder="Eind tekst"
            required
            value={newQuestion.property?.endText || ''}
            onChange={(event) =>
              setNewQuestion({
                ...newQuestion,
                property: {
                  ...newQuestion.property,
                  endText: event.target.value,
                },
              })
            }
          />
        </>
      ) : (
        <>
          <input
            className="w-full text-black rounded input bg-transparent col-span-2"
            placeholder="Advies voor ja"
            required
            value={newQuestion.property?.advices?.yes || ''}
            onChange={(event) =>
              setNewQuestion({
                ...newQuestion,
                property: {
                  ...newQuestion.property,
                  advices: {
                    ...(newQuestion.property?.advices || {}),
                    yes: event.target.value,
                  },
                },
              })
            }
          />
          <input
            className="w-full text-black rounded input bg-transparent col-span-2"
            placeholder="Advies voor nr"
            required
            value={newQuestion.property?.advices?.no || ''}
            onChange={(event) =>
              setNewQuestion({
                ...newQuestion,
                property: {
                  ...newQuestion.property,
                  advices: {
                    ...(newQuestion.property?.advices || {}),
                    no: event.target.value,
                  },
                },
              })
            }
          />
        </>
      )}

      <button type="submit" className="auth-button mb-4">
        {type === 'update' ? 'Update' : 'Toevoegen'}
      </button>
    </form>
  );
};

export default QuestionForm;
