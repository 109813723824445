import {
  CircularProgressbar,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CircularProgress = ({value}) => {
  return <CircularProgressbar value={value} text={`${value}%`} strokeWidth={10} styles={buildStyles({
          textColor: "black",
          pathColor: "#052e16",
        })}  />
}
export default CircularProgress