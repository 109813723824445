import React, { useEffect, useRef } from 'react';
// import { useAuth0 } from '@auth0/auth0-react';
import { withAuth0 } from '../../util/react-auth0-wrapper';
import Logo from '../../img/logo.png';
import { NavLink, Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Navbar = ({ useAuth0 }) => {
  const { isAuthenticated, logout, appUser } = useAuth0;
  const location = useLocation();
  const toggleBtnRef = useRef(null);
  const toggleInputRef = useRef(null);

const Menu = ({ className, isMobile = false }) => {
  return (
    <div className={`navbar-end items-start capitalize ${isMobile ? 'space-y-2 flex-col pt-[64px]' : 'space-x-5' } ${className}`}>
      {isAuthenticated && (
        <>
          {appUser?.role?.name === 'super admin' && (
            <Link
              className="text-black cursor-pointer"
              to={{
                pathname: '/select-organization',
                state: { from: location.pathname },
              }}
            >
              <div>
                <b>Geselecteerde organisatie:<br /></b><i>
                  {appUser?.organization?.name || 'Selecteer een organisatie'}</i>
                <button className="mx-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    className="w-[15px]"
                  >
                    <path d="M142.9 142.9c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5c0 0 0 0 0 0H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5c7.7-21.8 20.2-42.3 37.8-59.8zM16 312v7.6 .7V440c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l41.6-41.6c87.6 86.5 228.7 86.2 315.8-1c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.2 62.2-162.7 62.5-225.3 1L185 329c6.9-6.9 8.9-17.2 5.2-26.2s-12.5-14.8-22.2-14.8H48.4h-.7H40c-13.3 0-24 10.7-24 24z" />
                  </svg>
                </button>
              </div>
            </Link>
          )}
          <NavLink className="text-black cursor-pointer" to="/app">
            Nieuwe scan
          </NavLink>
          <NavLink className="text-black cursor-pointer" to="/app/history">
            Eerdere resultaten
          </NavLink>
          {appUser?.role?.name === 'super admin' && (
            <><p><br /><strong>Vragenlijstbeheer</strong></p>
              <NavLink
                className="text-black cursor-pointer"
                to="/app/admin/survey-topics"
              >
                Onderwerpen
              </NavLink>
              <NavLink
                className="text-black cursor-pointer"
                to="/app/admin/survey-questions"
              >
                Vragen
              </NavLink>
            </>
          )}

{appUser?.role?.name === 'super admin' && (
            <><p><br /><strong>Administratie</strong></p>
              <NavLink
                className="text-black cursor-pointer"
                to="/app/admin/organizations"
              >
                Organisaties
              </NavLink>

            </>
          )}

          {['super admin', 'admin', 'project manager'].includes(appUser?.role?.name) && (
            <>
              <NavLink
                className="text-black cursor-pointer"
                to="/app/admin/projects"
              >
                Projecten
              </NavLink>
              <NavLink
                className="text-black cursor-pointer"
                to="/app/admin/users"
              >
                Gebruikers
              </NavLink>
            </>
          )}


{appUser?.role?.name === 'super admin' && (
            <NavLink
              className="text-black cursor-pointer"
              to="/app/admin/roles"
            >
              Rollen
            </NavLink>
          )}


        </>
      )}
      {isAuthenticated && (
        <span className="text-black cursor-pointer" onClick={() => logout()}>
          <br />Uitloggen
        </span>
      )}
    </div>
  );
};


useEffect(() => {
  if (toggleBtnRef.current) {
    if(toggleInputRef.current?.checked){
      toggleBtnRef.current.click();
    }
  }
}, [location]);

  return (
    <>
      <div className="navbar navbar-sticky bg-white outline-none shadow-none px-5 lg:px-20 justify-between sand-background">
        <NavLink className="navbar-start w-max" to="/app">
          <img src={Logo} alt="site logo" className="h-20 w-20" />
        </NavLink>
        {isAuthenticated && (
          <label
            htmlFor="drawer-toggle"
            className="top-0 z-50 right-0 inline-block p-4 transition-all duration-500 rounded-lg peer-checked:rotate-180 peer-checked:right-64 cursor-pointer"
            ref={toggleBtnRef}
          >
            <FontAwesomeIcon className="text-xl" icon={faBars} />
          </label>
        )}
      </div>
      {isAuthenticated && (
        <div className="flex">
          <input
            type="checkbox"
            id="drawer-toggle"
            className="relative sr-only peer"
            ref={toggleInputRef}
          />
          <label
            htmlFor="drawer-toggle"
            className="bg-black invisible w-full h-screen fixed top-0 left-0 z-50 opacity-60 peer-checked:visible transition-all duration-400"
          ></label>
          <div className="fixed top-0 right-0 z-50 w-[300px] h-full transition-all duration-500 transform translate-x-full bg-white shadow-lg peer-checked:-translate-x-0">
            <div className="px-6 py-4 relative">
              <label
                htmlFor="drawer-toggle"
                className="absolute top-0 left-0 z-[100] p-[1.5rem] transition-all duration-500 rounded-lg  peer-checked:rotate-180 peer-checked:right-[300px] cursor-pointer"
              >
                <svg
                  width="17.5px"
                  height="17.5px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.70718 2.58574C4.31666 2.19522 3.68349 2.19522 3.29297 2.58574L2.58586 3.29285C2.19534 3.68337 2.19534 4.31654 2.58586 4.70706L9.87877 12L2.5859 19.2928C2.19537 19.6834 2.19537 20.3165 2.5859 20.7071L3.293 21.4142C3.68353 21.8047 4.31669 21.8047 4.70722 21.4142L12.0001 14.1213L19.293 21.4142C19.6835 21.8047 20.3167 21.8047 20.7072 21.4142L21.4143 20.7071C21.8048 20.3165 21.8048 19.6834 21.4143 19.2928L14.1214 12L21.4143 4.70706C21.8048 4.31654 21.8048 3.68337 21.4143 3.29285L20.7072 2.58574C20.3167 2.19522 19.6835 2.19522 19.293 2.58574L12.0001 9.87865L4.70718 2.58574Z"
                    fill="#000000"
                  />
                </svg>
              </label>
              <Menu isMobile={true} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default withAuth0(Navbar);
