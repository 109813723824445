import Axios from 'axios';
import { useEffect, useState } from 'react';
import Config from '../Config';
import { toast } from 'react-toastify';
import Modal from '../components/common/Modal';
import Table from '../components/common/Table';
import AlertMessage from '../components/common/AlertMessage';

const Topic = ({ currentUser }) => {
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState(null);
  const [topics, setTopics] = useState([]);
  const [newTopic, setNewTopic] = useState('');
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showOrganizationAlert, setShowOrganizationAlert] = useState(false);

  const warningMessage =
   'Je account is nog niet gekoppeld aan een organisatie. Neem contact op met je contactpersoon om verder te gaan.';

  const fields = {
    title: 'Titel',
    questions: 'Vragen',
  };

  const addTopic = async (event) => {
    event.preventDefault();
    if (showOrganizationAlert) return;

    try {
      await Axios.post(`${Config.apiUrl}/api/v1/survey-topics`, {
        title: newTopic?.trim(),
        organization: currentUser?.organization?._id,
      });
      setNewTopic('');
      toast('Topic added successfully');
      await fetchData();
    } catch (error) {
      toast(error?.response?.data?.message, {
        type: 'error',
      });
    }
  };

  const fetchData = async () => {
    try {
      const {
        data: { data, meta},
      } = await Axios.get(`${Config.apiUrl}/api/v1/survey-topics?page=${page}`);

      if (data?.length === 0 && page > 1) {
        setPage((prev) => prev - 1);
      }

      setMeta(meta);
      setTopics(data);
    } catch (error) {
      toast('Failed to fetch topics. Please try again later.', {
        type: 'error',
      });
    }
  };

  const onUpdate = (role) => {
    if (showOrganizationAlert) return;
    setSelectedTopic(role);
    setShowUpdateModal(true);
  };

  const onDelete = async (role) => {
    if (showOrganizationAlert) return;
    setSelectedTopic(role);
    setShowDeleteModal(true);
  };

  const deleteRole = async () => {
    try {
      await Axios.delete(
        `${Config.apiUrl}/api/v1/survey-topics/${selectedTopic?._id}`,
      );

      setTopics((prevTopics) =>
        prevTopics.filter((o) => o._id !== selectedTopic?._id),
      );
      setSelectedTopic(null);
      setShowDeleteModal(false);

      await fetchData();
      toast('Topic deleted successfully');
    } catch (error) {
      toast(error?.response?.data?.message, {
        type: 'error',
      });
    }
  };

  const handleUpdateTopic = (event) => {
    let title = event.target.value;

    setSelectedTopic({
      ...selectedTopic,
      title,
    });
  };

  const updateTopic = async (event) => {
    event.preventDefault();

    try {
      await Axios.patch(
        `${Config.apiUrl}/api/v1/survey-topics/${selectedTopic?._id}`,
        {
          title: selectedTopic?.title?.trim(),
          organization: currentUser?.organization?._id,
        },
      );

      setTopics((prev) => {
        let index = prev.findIndex((o) => o._id === selectedTopic?._id);

        prev[index] = {
          ...selectedTopic,
        };
        setSelectedTopic(null);
        setShowUpdateModal(false);

        return prev;
      });

      toast('Topic updated successfully');
    } catch (error) {
      toast(error?.response?.data?.message, {
        type: 'error',
      });
    }
  };

  useEffect(() => {
    if (currentUser) {
        fetchData();

      if (currentUser.role.name === 'admin' && !currentUser?.organization) {
        setShowOrganizationAlert(true);
      }
    }
    // eslint-disable-next-line
  }, [page]);

  return (
    <div className="h-screen pt-32 px-4">
      {showOrganizationAlert && <AlertMessage message={warningMessage} />}
      <form
        className={`flex space-x-4 ${
          showOrganizationAlert && 'opacity-60 pointer-events-none select-none'
        }`}
        onSubmit={addTopic}
      >
        <input
          className="w-[400px] text-black rounded input bg-transparent"
          placeholder="Voer de naam van de onderwerp in"
          value={newTopic}
          required
          onChange={(event) => setNewTopic(event.target.value)}
        />
        <button type="submit" className="auth-button w-[200px] mb-4">
          Toevoegen
        </button>
      </form>
      {showUpdateModal && (
        <Modal title="Onderwerp" close={() => setShowUpdateModal(false)}>
          <form className="flex space-x-4" onSubmit={updateTopic}>
            <input
              className="w-[400px] text-black rounded input bg-transparent"
              placeholder="Voer de naam van de onderwerp in"
              value={selectedTopic?.title}
              onChange={handleUpdateTopic}
              required
            />
            <button type="submit" className="auth-button w-[200px]">
              Update
            </button>
          </form>
        </Modal>
      )}
      {showDeleteModal && (
        <Modal
          title="Onderwerp"
          action="Verwijderen"
          close={() => setShowDeleteModal(false)}
          confirm={deleteRole}
          type="delete"
          name={selectedTopic?.title}
        />
      )}
      <Table
        fields={fields}
        data={topics}
        actions={{ onUpdate, onDelete }}
        hasPagination={true}
        page={page}
        setPage={setPage}
        meta={meta}
        className={
          showOrganizationAlert && 'opacity-60 pointer-events-none select-none'
        }
      />
    </div>
  );
};
export default Topic;
