import Axios from 'axios';
import { useEffect, useState } from 'react';
import Config from '../Config';
import { toast } from 'react-toastify';
import Modal from '../components/common/Modal';
import Table from '../components/common/Table';
import AlertMessage from '../components/common/AlertMessage';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Redirect } from 'react-router-dom';

const Project = ({ currentUser }) => {
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState(null);
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [newProject, setNewProject] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showOrganizationAlert, setShowOrganizationAlert] = useState(false);

  const warningMessage =
    'Je account is nog niet gekoppeld aan een organisatie. Neem contact op met je contactpersoon om verder te gaan.';

  const fields = {
    name: 'Naam',
    users: 'Gebruikers',
  };

  const actionField = (item) => {
    return (
      <button onClick={() => onAddUser(item)}>
        <FontAwesomeIcon className="text-xl" icon={faUserPlus} />
      </button>
    );
  };

  const addProject = async (event) => {
    event.preventDefault();
    try {
      await Axios.post(`${Config.apiUrl}/api/v1/projects`, {
        name: newProject?.trim(),
        organization: currentUser?.organization?._id,
      });
      setNewProject('');
      await fetchData();
      toast('Project succesvol toegevoegd');
    } catch (error) {
      toast(error?.response?.data?.message, {
        type: 'error',
      });
    }
  };

  const fetchData = async () => {
    try {
      const {
        data: { data, meta },
      } = await Axios.get(`${Config.apiUrl}/api/v1/projects?page=${page}`);

      if (data?.length === 0 && page > 1) {
        setPage((prev) => prev - 1);
      }

      setMeta(meta);
      setProjects(data);
    } catch (error) {
      toast('Failed to fetch projects. Please try again later.', {
        type: 'error',
      });
    }
  };

  const fetchUsers = async () => {
    try {
      const {
        data: { data },
      } = await Axios.get(`${Config.apiUrl}/api/v1/users/all`);

      setUsers(data);
    } catch (error) {
      toast('Failed to fetch users. Please try again later.', {
        type: 'error',
      });
    }
  };

  const onAddUser = (project) => {
    setShowAddUserModal(true);
    setSelectedProject(project);
  };

  const onUpdate = (project) => {
    setSelectedProject(project);
    setShowUpdateModal(true);
  };

  const onDelete = async (project) => {
    setSelectedProject(project);
    setShowDeleteModal(true);
  };

  const deleteProject = async () => {
    try {
      await Axios.delete(
        `${Config.apiUrl}/api/v1/projects/${selectedProject?._id}`,
      );

      setProjects((prevProjects) =>
        prevProjects.filter((o) => o._id !== selectedProject?._id),
      );

      setSelectedProject(null);
      setShowDeleteModal(false);
      await fetchData();
      toast('Project deleted successfully');
    } catch (error) {
      toast(error?.response?.data?.message, {
        type: 'error',
      });
    }
  };

  const handleUpdateProject = (event) => {
    let name = event.target.value;

    setSelectedProject({
      ...selectedProject,
      name,
    });
  };

  const updateProject = async (event) => {
    event.preventDefault();

    try {
      await Axios.patch(
        `${Config.apiUrl}/api/v1/projects/${selectedProject?._id}`,
        {
          name: selectedProject?.name?.trim(),
          organization: currentUser?.organization?._id,
        },
      );

      setProjects((prev) => {
        let index = prev.findIndex((o) => o._id === selectedProject?._id);

        prev[index] = {
          ...selectedProject,
        };
        setSelectedProject(null);
        setShowUpdateModal(false);

        return prev;
      });

      toast('Project updated successfully');
    } catch (error) {
      toast(error?.response?.data?.message, {
        type: 'error',
      });
    }
  };

  const addUserToProject = async (event) => {
    event.preventDefault();

    try {
      await Axios.patch(
        `${Config.apiUrl}/api/v1/users/${selectedUser}/assign-project`,
        {
          projectId: selectedProject?._id,
        },
      );

      setSelectedUser('');
      setSelectedProject(null);
      setShowAddUserModal(false);
      await fetchData();
      await fetchUsers();

      toast('User added successfully');
    } catch (err) {
      toast(err?.response?.data?.message || 'Failed to add user to project.', {
        type: 'error',
      });
    }
  };

  useEffect(() => {
    if (currentUser) {
      if (currentUser?.organization?.name) {
        fetchData();
        fetchUsers();
      }

      if (currentUser.role.name === 'admin' && !currentUser?.organization) {
        setShowOrganizationAlert(true);
      }
    }
    // eslint-disable-next-line
  }, [page]);

  if (currentUser?.role?.name === 'super admin') {
    let organization = localStorage.getItem('sa-organization');

    if (!organization) {
      return <Redirect to="/select-organization" />;
    }
  }

  return (
    <div className="h-screen pt-32 px-4">
      {showOrganizationAlert && <AlertMessage message={warningMessage} />}
      <div
        className={
          showOrganizationAlert
            ? 'opacity-60 pointer-events-none select-none'
            : ''
        }
      >
        {currentUser?.role?.name !== 'project manager' &&<form className="flex space-x-4" onSubmit={addProject}>
          <input
            className="w-[400px] text-black rounded input bg-transparent"
            placeholder="Voer de naam van de project in"
            required
            value={newProject}
            onChange={(event) => setNewProject(event.target.value)}
          />
          <button type="submit" className="auth-button w-[200px] mb-4">
            Toevoegen
          </button>
        </form>}
      </div>
      {showUpdateModal && (
        <Modal title="Project" close={() => setShowUpdateModal(false)}>
          <form className="flex space-x-4" onSubmit={updateProject}>
            <input
              className="w-[400px] text-black rounded input bg-transparent"
              placeholder="Voer de naam van de project in"
              value={selectedProject?.name}
              onChange={handleUpdateProject}
              required
            />
            <button type="submit" className="auth-button w-[200px]">
              Update
            </button>
          </form>
        </Modal>
      )}
      {showDeleteModal && (
        <Modal
          title="Project"
          action="Verwijderen"
          close={() => setShowDeleteModal(false)}
          confirm={deleteProject}
          type="delete"
          name={selectedProject?.name}
        />
      )}
      {showAddUserModal &&
      (currentUser?.role?.name === 'super admin'
      || currentUser?.role?.name === 'project manager')
      && (
        <Modal
          title="Gebruiker toevoegen"
          close={() => setShowAddUserModal(false)}
          showFullTitle
        >
          <form className="flex space-x-4" onSubmit={addUserToProject}>
            <select
              className="select rounded"
              value={selectedUser}
              onChange={(event) => setSelectedUser(event.target.value)}
              required
            >
              <option value="">Selecteer gebruiker</option>
              {users?.map((user) => (
                <option key={user?._id} value={user?._id}>
                  {user?.name}
                </option>
              ))}
            </select>
            <button type="submit" className="auth-button w-[200px]">
              Toevoegen
            </button>
          </form>
          <p><br /><i>Staat de gebruiker er niet tussen? <a href="/app/admin/users"><u>Maak eerst het gebruikersaccount aan</u></a>.</i></p>
        </Modal>
      )}
      <Table
        fields={fields}
        data={projects}
        actions={{ onUpdate, onDelete }}
        extraActionFields={
          currentUser?.role?.name === 'super admin' ||
          currentUser?.role?.name === 'project manager' ? [actionField] : []
        }
        hasPagination={true}
        page={page}
        setPage={setPage}
        meta={meta}
        hideActions={currentUser?.role?.name === 'project manager'}
      />
    </div>
  );
};
export default Project;
