import Logo from '../img/logo.png';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '../util/react-auth0-wrapper';

const Auth0Login = () => {
const { isAuthenticated, loginWithRedirect } = useAuth0();
const history = useHistory();

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/app');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <div className="text-black h-screen  flex items-center justify-center">
      <div className="self-center flex items-center flex-col space-y-10">
          <img src={Logo} alt="site logo" className="w-[80px]" />
          <h3 className="text-xl font-bold">Login to continue</h3>
           <button className="auth-button w-full mb-4" onClick={() => loginWithRedirect()}>Login</button>
        </div>
    </div>
  );
};

export default Auth0Login;
