import Axios from 'axios';
import { useEffect, useState } from 'react';
import Config from '../Config';
import { toast } from 'react-toastify';
import Modal from '../components/common/Modal';
import QuestionForm from '../components/question/QuestionForm';
import Table from '../components/common/Table';
import AlertMessage from '../components/common/AlertMessage';

const Question = ({ currentUser }) => {
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showOrganizationAlert, setShowOrganizationAlert] = useState(false);

  const warningMessage =
  'Je account is nog niet gekoppeld aan een organisatie. Neem contact op met je contactpersoon om verder te gaan.';

  const fields = {
    title: 'Titel',
    topic: 'Onderwerp',
    category: 'Categorie',
  };

  const transformData = (data) => {
    return data?.map((item) => ({
      _id: item?._id,
      title: item?.title,
      topic: item?.topic?.title,
      category: getCategory(item?.category),
    }));
  };

  const addQuestion = async (newQuestion, setNewQuestion) => {
    if (showOrganizationAlert) return;

    try {
      if (!newQuestion.property) {
        newQuestion.property = {};
      }
      newQuestion.title = newQuestion.title?.trim();
      newQuestion.organization = currentUser?.organization?._id;

      await Axios.post(`${Config.apiUrl}/api/v1/survey-questions`, newQuestion);
      setNewQuestion(() => ({
        topic: '',
        category: '',
        type: '',
      }));

      await fetchData();

      toast('Question added successfully');
    } catch (error) {
      toast(error?.response?.data?.message, {
        type: 'error',
      });
    }
  };

  const fetchData = async () => {
    try {
      const {
        data: { data, meta },
      } = await Axios.get(`${Config.apiUrl}/api/v1/survey-questions?page=${page}`);

      if (data?.length === 0 && page > 1) {
        setPage((prev) => prev - 1);
      }

      setMeta(meta);
      setQuestions(data);
    } catch (error) {
      toast('Failed to fetch questions. Please try again later.', {
        type: 'error',
      });
    }
  };

  const onUpdate = (question) => {
    if (showOrganizationAlert) return;
    setSelectedQuestion({
      ...question,
      topic: question?.topic?._id,
    });
    setShowUpdateModal(true);
  };

  const onDelete = async (role) => {
    if (showOrganizationAlert) return;
    setSelectedQuestion(role);
    setShowDeleteModal(true);
  };

  const deleteQuestion = async () => {
    try {
      await Axios.delete(
        `${Config.apiUrl}/api/v1/survey-questions/${selectedQuestion?._id}`,
      );

      setQuestions((prevQuestions) =>
        prevQuestions.filter((o) => o._id !== selectedQuestion?._id),
      );
      setSelectedQuestion(null);
      setShowDeleteModal(false);

      await fetchData();
      toast('Question deleted successfully');
    } catch (error) {
      toast(error?.response?.data?.message, {
        type: 'error',
      });
    }
  };

  const updateQuestion = async (question) => {
    try {
      let {
        data: { data },
      } = await Axios.patch(
        `${Config.apiUrl}/api/v1/survey-questions/${question?._id}`,
        question,
      );

      setQuestions((prev) => {
        let index = prev.findIndex((o) => o._id === question?._id);

        prev[index] = {
          ...data,
        };
        setSelectedQuestion(null);
        setShowUpdateModal(false);

        return prev;
      });
      
      await fetchData();

      toast('Question updated successfully');
    } catch (error) {
      toast(error?.response?.data?.message, {
        type: 'error',
      });
    }
  };

  const getCategory = (category) => {
    return [
      {
        name: 'Snel',
        value: 'quick',
      },
      {
        name: 'Uitgebreid',
        value: 'extensive',
      },
    ].find((cat) => cat.value === category)?.name;
  };

  useEffect(() => {
    if (currentUser) {
        fetchData();
     
      if (currentUser.role.name === 'admin' && !currentUser?.organization) {
        setShowOrganizationAlert(true);
      }
    }
    // eslint-disable-next-line
  }, [page]);

  return (
    <div className="h-screen pt-32 px-4">
      {showOrganizationAlert && <AlertMessage message={warningMessage} />}
      <div
        className={
          showOrganizationAlert ? 'opacity-60 pointer-events-none select-none' : ""
        }
      >
        <QuestionForm onSubmit={addQuestion} currentUser={currentUser}/>
      </div>
      {showUpdateModal && (
        <Modal
          title="Vraag"
          action="Update"
          close={() => setShowUpdateModal(false)}
          confirm={deleteQuestion}
          name={selectedQuestion?.title}
        >
          <QuestionForm
            onSubmit={updateQuestion}
            type="update"
            question={selectedQuestion}
            currentUser={currentUser}
          />
        </Modal>
      )}
      {showDeleteModal && (
        <Modal
          title="Vraag"
          action="Verwijderen"
          close={() => setShowDeleteModal(false)}
          confirm={deleteQuestion}
          type="delete"
          name={selectedQuestion?.title}
        />
      )}
      <Table
        fields={fields}
        data={questions}
        transform={true}
        transformData={transformData}
        actions={{ onUpdate, onDelete }}
        hasPagination={true}
        page={page}
        setPage={setPage}
        meta={meta}
        className={
          showOrganizationAlert && 'opacity-60 pointer-events-none select-none'
        }
      />
    </div>
  );
};
export default Question;
