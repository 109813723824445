import { useEffect, useState } from 'react';

const Question = ({
  index,
  answer,
  startText,
  endText,
  onChange,
  type,
  ...question
}) => {
  const [isTaken, setIsTaken] = useState(question?.isTaken || false);

  const updateTooltip = () => {
    let thumbnWidth = 14;
    const range = document.querySelector('#range-slider');
    const tooltip = document.querySelector('#range-tooltip');
    let offset = range.clientWidth / 10;

    let left =
      (range.valueAsNumber - 0) * offset -
      (range.valueAsNumber / 10 - 0 - 0.5) * thumbnWidth;

    tooltip.style.setProperty('--left', `${left - 14}px`);
    tooltip.textContent = range.value;
  };

  const handleChnage = (e) => {
    onChange(e);
    if (type === 'range') {
      updateTooltip();
    }
    setIsTaken(true);
  };

  useEffect(() => {
    if (type === 'range') {
      updateTooltip();
    }
  }, [index, type]);

  return (
    <div className="flex flex-col space-y-14 mb-16 md:mb-24 p-5 m-5" style={{
      backgroundColor: 'white',
      border: '2px solid rgb(0, 107, 0)',
      borderRadius: '20px',
    }}>
      <h5 className="text-center font-bold text-xl md:text-2xl mb-10">
        {index + 1}. {question?.title}
      </h5>
      <div className="lg:px-36 xl:px-48">
        <div className="flex relative items-start">
          <div className="min-w-max pr-2 pt-0.5 text-sm md:text-lg font-bold flex items-center">
            <span>{question?.property?.startText}</span>
          </div>

          {type === 'range' ? (
            <div className="pt-1 w-full">
              <div className="relative">
                <span id="range-tooltip">0</span>
                <input
                  type="range"
                  step={1}
                  className="bg-transparent range-slider"
                  id="range-slider"
                  onChange={handleChnage}
                  max={10}
                  value={answer}
                />
              </div>
              <div className="w-full flex justify-between  font-semibold">
                <span className="block">0</span>
                <span className="block" style={{ marginLeft: '5px' }}>
                  5
                </span>
                <span className="block">10</span>
              </div>
            </div>
          ) : (
            <div className="pt-1 w-full">
              <div id="group1" className="flex flex-row gap-3 justify-center">
                <label className="flex cursor-pointer gap-2">
                  <input
                    type="radio"
                    className="radio radio-success"
                    name="group1"
                    onChange={handleChnage}
                    checked={isTaken && answer === 10}
                    value="10"
                  />
                  <span>Ja</span>
                </label>
                <label className="flex cursor-pointer gap-2">
                  <input
                    type="radio"
                    className="radio radio-error"
                    name="group1"
                    onChange={handleChnage}
                    checked={isTaken && answer === 0}
                    value="0"
                  />
                  <span>Nee</span>
                </label>
              </div>
            </div>
          )}

          <div className="min-w-max pt-0.5 text-sm md:text-lg  font-bold flex items-center pl-2">
            <span>{question?.property?.endText}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Question;
