import React from 'react';

const Modal = ({
  children,
  action = 'Update',
  title = 'Title',
  close = () => {},
  confirm = () => {},
  type = 'form',
  name = '',
  large = false,
  showFullTitle = false,
}) => {
  return (
    <div
      className="relative z-[60] modal-start"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-black/20 bg-opacity-[55] transition-opacity"></div>

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 mr-4 text-center sm:p-0">
          <div
            className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8  ${
              large ? 'w-4/5' : 'sm:max-w-lg sm:w-full'
            }`}
          >
            <div
              className={`bg-white px-4 pt-3 ${type !== 'delete' && 'pb-6'}`}
            >
              <div
                className={`flex justify-between  ${
                  type !== 'delete' && 'mb-4'
                }`}
              >
                <p className="text-black" id="modal-title">
                  {!showFullTitle && action} {title}
                </p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                  className="text-gray-500 w-6 h-6 cursor-pointer"
                  onClick={close}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </div>

              {type === 'delete' && (
                <div className="sm:flex sm:items-start">
                  <div className="mt-3">
                    <div>
                      <p className="text-sm text-gray-500">
                        Weet u zeker dat u{name && <b> "{name}" </b>} wilt{' '}
                        {action?.toLocaleLowerCase()}? Alle gegevens worden
                        definitief verwijderd. Deze actie kan niet ongedaan
                        gemaakt worden.
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {type !== 'delete' && children}
            </div>
            {type === 'delete' && (
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  onClick={confirm}
                  type="button"
                  className="bg-black inline-flex justify-center px-6 py-2 rounded-full shadow-sm sm:w-auto text-sm text-white text-whitet w-full ml-3"
                >
                  Bevestig
                </button>
                <button
                  onClick={close}
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-full bg-white px-6 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Annuleer
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
