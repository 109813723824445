import { useEffect, useState } from "react"
import Progress from "../components/survey/Progress"
import Question from "../components/survey/Question"
import Heart from "../img/heart.png"
import Time from "../img/timer.png"
import { useParams, useHistory } from "react-router"
import { toast } from "react-toastify";
import config from "../Config"
import axios from "axios"

const SurveyQuestion = ({ currentUser }) => {
  const [topics, setTopics] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [savedSurvey, setSavedSurvey] = useState(null);
  const [isLoading, setIsloading] = useState(true);
  const params = useParams();
  let history = useHistory();

  const next = () => {
    trackProgress();
    setCurrentIndex(currentIndex === savedSurvey.answers.length - 1 ?  savedSurvey.answers.length - 1 : currentIndex + 1);
  }

  const previous = () => {
    if(currentIndex < 1) return;
    setCurrentIndex(currentIndex - 1);
  }

  const onChange = (event) => {
    const updateSavedSurvey = {...savedSurvey};
    updateSavedSurvey.answers[currentIndex].answer = +event.target.value;
    setSavedSurvey(updateSavedSurvey);
  }

  const onSave = async () => {
    trackProgress();
    try {
        const {data: { data}} = await axios.post(`${config.apiUrl}/api/v1/survey-histories`, {
          answer: savedSurvey
        });
        history.push(`/app/surveys/${data._id}/preview`)
    } catch (error) {
      toast(error?.response?.data?.message, {
        type: 'error',
      });
    }
  }

  const trackProgress =  () => {
    const updateSavedSurvey = {...savedSurvey};
    updateSavedSurvey.answers[currentIndex].isTaken = true;
    setSavedSurvey(updateSavedSurvey);
    localStorage.setItem('survey', JSON.stringify(updateSavedSurvey))
  }

  useEffect( () => {
      const saved = JSON.parse(localStorage.getItem('survey'));

      if(saved != null && saved?.id === +params.id) {
        setSavedSurvey(saved);
         const previousIndex = savedSurvey?.answers?.findIndex(item => item.isTaken);
         if(previousIndex){
          setCurrentIndex(previousIndex + 1);
         }
        return;
      } 

     async function fetchData() {
        try {
          const {data: { data}} = await axios.get(`${config.apiUrl}/api/v1/survey-questions?category=${params.category}&all=1`);
          if(data?.length === 0) {
            setSavedSurvey(null);
            return;
          }
          const questions = {
            _id: data.at(0)?._id,
            title: data.at(0)?.title,
            type: params.category,
            answers: data?.sort((a, b) => a.topic?.title.localeCompare(b.topic.title, undefined, { sensitivity: 'base' })).map(item => ({...item, answer: 0, isTaken: false}))
          }
          setSavedSurvey(questions)
        } catch (error) {
          toast('Failed to fetch questions. Please try again later.', {
            type: 'error',
          });
        } finally {
          setIsloading(false);
        }
      }

      fetchData();
    // eslint-disable-next-line
  }, [params]);
  
  useEffect( () => {
    if(savedSurvey){
      const topics = Array.from(new Set(savedSurvey.answers.map(question => question?.topic?.title))).map(topic => {
        const newItem = { text: topic, value: 0, max: 0};
        savedSurvey.answers.forEach(item => {
          if(item?.topic?.title === topic) {
            newItem.max++;
          }
          if(item?.topic?.title === topic && item.isTaken) {
            newItem.value++;
          }
        })

        return newItem;
      });
      setTopics(topics)
    }
  }, [savedSurvey]);

  return <>
      {savedSurvey != null
      ? 
       <div className="relative flex flex-col justify-center min-h-screen">
        <Question {...savedSurvey.answers[currentIndex]} index={currentIndex} onChange={onChange}/>
        
        <div className="flex justify-between w-full md:px-14">
          <button className="btn btn-outline hover:bg-black text-black hover:text-white btn-rounded" onClick={previous} disabled={!currentIndex}>Vorige</button>
          {savedSurvey?.answers?.length - 1 === currentIndex
          ?  <button className="button hover:bg-black" onClick={onSave}>Save</button>
          : <button className="button hover:bg-black" onClick={next}>Volgende</button>
        }
          
        </div>

        <div className="w-full flex absolute md:px-14 bottom-10">
          <div className="md:w-[90%] w-full grid grid-cols-2 lg:grid-cols-6 md:px-3 gap-y-4 gap-x-4 items-center">
            {topics.map((item, index) => <Progress title={item.text} max={item.max} value={item.value} key={index}/>)}
          </div>
          <div className="md:block md:col-span-1">
            <img src={params.category === 'quick' ? Time : Heart} alt="question type" className="hidden md:block w-40 h-40 -right-14 md:-right-4 lg:-right-0 -top-32 md:-top-14" />
          </div>
        </div>
      </div>
      :
      <>
      {!isLoading && !savedSurvey && (
        <div className="h-screen pt-32 px-4">
          <h6>Er is geen vraag gevonden voor deze categorie</h6>
        </div>
      )}
    </>
    }
     
  </>
}
export default SurveyQuestion