import React from 'react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import 'array-flat-polyfill';
import { Switch } from "react-router-dom";
import { Route } from 'react-router';
import Navbar from './components/layout/Navbar';
import  SurveyComponent  from './pages/Survey';
import SurveyQuestion from './pages/SurveyQuestion';
import SurveyPreview from './pages/SurveyPreview';
import { AppContextProvider } from './context/appContext';
import SurveyHistory from './pages/SurveyHistory';
import { UserContextProvider } from './context/auth0Context';
import Role from './pages/Role';
import Organization from './pages/Organization';
import User from './pages/Users';
import Auth0Login from './pages/Auth0Login';
import PrivateRoute from './components/common/PrivateRoute';
import { Redirect, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Question from './pages/Question';
import Topic from './pages/Topic';
import { useAuth0 } from './util/react-auth0-wrapper';
import SelectOrganization from './pages/SelectOrganization';
import Project from './pages/Project';

const noNavbar = [
  '/login'
];
const App = () => {
const history = useHistory();
const { appUser } = useAuth0();

return <AppContextProvider>
    <UserContextProvider>
            <main className='overflow-x-hidden px-4 md:px-14'>
              {! noNavbar.includes(history.location.pathname) && <Navbar />}
                <Switch>
                    <PrivateRoute path="/app/admin/roles" roles={['super admin']} render={(props) => <Role />} />
                    <PrivateRoute path="/select-organization" roles={['super admin']} render={(props) => <SelectOrganization />} />
                    <PrivateRoute path="/app/admin/projects" roles={['admin', 'super admin', 'project manager']} render={(props) => <Project currentUser={appUser}/>} />
                    <PrivateRoute path="/app/admin/organizations" roles={['super admin']} render={(props) => <Organization currentUser={appUser}/>} />
                    <PrivateRoute path="/app/admin/survey-topics" roles={['super admin']} render={(props) => <Topic currentUser={appUser}/>} />
                    <PrivateRoute path="/app/admin/survey-questions" roles={['super admin']} render={(props) => <Question currentUser={appUser}/>} />
                    <PrivateRoute path="/app/admin/users" roles={['admin', 'super admin', 'project manager']} render={(props) => <User currentUser={appUser}/>} />
                    <PrivateRoute path="/app/surveys/:id/preview" render={(props) => <SurveyPreview currentUser={appUser}/>} />
                    <PrivateRoute path="/app/history/:user" render={(props) => <SurveyHistory />} />
                    <PrivateRoute path="/app/history" render={(props) => <SurveyHistory />} />
                    <PrivateRoute path="/app/surveys/:category" render={(props) => <SurveyQuestion currentUser={appUser}/>} />
                    <PrivateRoute path="/app" render={(props) => <SurveyComponent currentUser={appUser}/>} />
                    <Route path="/login">
                        <Auth0Login />
                    </Route>
                    <Route exact path="/">
                        <Auth0Login />
                    </Route>
                    <Route path="*">
                        <Redirect to="/app"/>
                    </Route>
                </Switch>
            </main>
    </UserContextProvider>
    </AppContextProvider>
}


export default App;
